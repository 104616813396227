import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import Layout from "../components/Layout";
import "../style/crew.scss";
import LinkedInIcon from "/assets/icons/fontawesome/linkedin.svg";
import EmailIcon from "/assets/icons/fontawesome/email.svg";

const isBrowser = typeof window !== "undefined";

const translations = {
  back: {
    en: "Back",
    es: "Back",
    it: "Back",
  },
  readmore: {
    en: "Read more",
    es: "Read more",
    it: "Leggi di più",
  },
};

const CrewPage = ({ data, pageContext, path, location }) => {
  const params = new URLSearchParams(location?.search);
  var crewMember = params.get("member");
  const { frontmatter } = data.markdownRemark;
  const content = frontmatter[pageContext.language || "en"];

  const crewMemberIndex = Math.max(
    crewMember
      ? content.crewsection.crewmembers.findIndex(
          (ele) => ele.title2 === crewMember
        )
      : 0,
    0
  );

  const [selectedCrewMember, setSelectedCrewMember] = useState(crewMemberIndex);

  const homeUrl =
    pageContext.language === "en" ? "/" : `/${pageContext.language}/`;

  useEffect(() => {
    if (isBrowser) window.scrollTo(0, 0);
  }, [selectedCrewMember]);

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <div id="crew-page">
        <section id="crew-hero">
          <div>
            <div>
              <div className="back-arrow">
                <Link to={homeUrl + "about/"}>
                  <ArrowIcon fill="white" />
                </Link>
              </div>
              <div className="catchy">
                {content.crewsection.crewmembers[selectedCrewMember].title1}
              </div>
              <h2>
                {content.crewsection.crewmembers[selectedCrewMember].title2}
              </h2>
              <div className="catchy smaller">
                "{content.crewsection.crewmembers[selectedCrewMember].motto}"
              </div>
            </div>
          </div>
          <div className="main-content">
            <div>
              <h3>
                {content.crewsection.crewmembers[selectedCrewMember].about}
              </h3>
              <p>
                <i>
                  {
                    content.crewsection.crewmembers[selectedCrewMember]
                      .description
                  }
                </i>
              </p>
              <div className="social-icons">
                {content.crewsection.crewmembers[selectedCrewMember]
                  .linkedin && (
                  <Link
                    to={
                      content.crewsection.crewmembers[selectedCrewMember]
                        .linkedin
                    }
                    target="_blank"
                  >
                    <LinkedInIcon />
                  </Link>
                )}
                {content.crewsection.crewmembers[selectedCrewMember].email && (
                  <div>
                    <a
                      style={{ lineHeight: "0" }}
                      href={`mailto:${content.crewsection.crewmembers[selectedCrewMember].email}`}
                    >
                      <EmailIcon />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div
                className="img"
                style={{
                  backgroundImage: `url(${content.crewsection.crewmembers[selectedCrewMember].crewimage.publicURL})`,
                }}
              ></div>
            </div>
          </div>
        </section>
        <section id="all-crew">
          <div>
            <div className="catchy">{content.crewsection.title1}</div>
            <h2>{content.crewsection.title2}</h2>
          </div>
          <div id="crew-members-container">
            <div id="crew-members">
              {content.crewsection.crewmembers?.map((member, index) => {
                if (index === selectedCrewMember) return <></>;

                return (
                  <div className="crew-member">
                    <div
                      style={{
                        display: "flex",
                        borderRadius: "8px",
                        flexGrow: 1,
                        backgroundImage: `url(${member.crewimage.publicURL})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        filter: "grayscale(1)",
                        borderRadius: "8px",
                        width: "14rem",
                        aspectRatio: "1 / 1",
                        maxWidth: "100%",
                        minWidth: "20%",
                      }}
                    ></div>
                    <div className="main-content">
                      <div className="catchy">{member.title1}</div>
                      <h3>{member.title2}</h3>
                      <button
                        className="mach9"
                        onClick={() => setSelectedCrewMember(index)}
                      >
                        <div>
                          {translations.readmore[pageContext.language]}
                          <ArrowIcon />
                        </div>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

CrewPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CrewPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { en: { templateKey: { eq: "crew-page" } } }) {
      frontmatter {
        en {
          crewsection {
            title1
            title2
            crewmembers {
              title1
              title2
              motto
              about
              description
              crewimage {
                publicURL
              }
              linkedin
              email
            }
          }
        }
        it {
          crewsection {
            title1
            title2
            crewmembers {
              title1
              title2
              motto
              about
              description
              crewimage {
                publicURL
              }
              linkedin
              email
            }
          }
        }
        es {
          crewsection {
            title1
            title2
            crewmembers {
              title1
              title2
              motto
              about
              description
              crewimage {
                publicURL
              }
              linkedin
              email
            }
          }
        }
      }
    }
  }
`;
